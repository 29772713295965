import React, { useEffect, useState } from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import SocialLinks from '../components/SocialLinks'
import SanitySlider from '../Sanity/SanitySlider'
import Marquee from '../components/Marquee'

// import Modal from '../components/Modal'


function Page(props) {
  const { data } = props

	const [refVideo, refVideoInView] = useInView({ triggerOnce: true })
  
  const sanityPage = data?.sanityPageHome
  const quickHTML = sanityPage?.quickHTML?.code
  

  // Keeping track of height of header and viewport so that we can keep the hero around viewport
  // const [slideHeight, setSlideHeight] = useState(0)
  // const textSpace = '(1em + 2vw)'
  // useEffect(() => {
  //   // Checking window size, dropping values into state
  //   function updateSize() {
  //     const header = document?.getElementById('fitheight')
  //     const height = 'calc(' + (window.innerHeight - header.offsetHeight) + 'px - ' + textSpace + ')'
  //     setSlideHeight(height)
  //   }
  //   window.addEventListener('resize', updateSize)
  //   updateSize()

  //   // Kill off listener
  //   return () => window.removeEventListener('resize', updateSize)
  // },[])


  return (
    <>

      <MainFrameCover />

      {/* <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal> */}

      <Marquee {...data?.sanityPageLanding} />

      <main id="mainframe" className={`active main-landing`}>

        <Seo title={"Home"} siteTitle={'18th Annual Irie Weekend'} description={"18th Annual Irie Weekend"} path={props.location?.pathname ? props.location?.pathname : ''} />
        
        <section ref={refVideo} className="section-hero c0 padd-half flex ac-center">

          <div id={'fitheight'} className="flex-12">

            <div className="span-3 span-12-mobile flex padd-half ac-center ai-center">
              <Intro visible={refVideoInView} in={{ fadeRight: 700 }} delayIn={400} mounted={true} stay={true} className="hero-logo overflow-visible">
                <>
                  <div className="aspect-ratio">
                    <StaticImage
                      src={'../../static/images/hero_txt_1.png'}
                      layout="fixed"
                      objectFit="contain"
                      objectPosition={'50% 50%'}
                      placeholder='NONE'
                      alt="18th Annual Irie Weekend"
                    />
                  </div>
                </>
              </Intro>

              <Intro visible={refVideoInView} in={{ fadeRight: 700 }} delayIn={500} mounted={true} stay={true} className="overflow-visible stay-connected-wrap">
                  <p className='h6 stay-connected'>Stay Connected</p>
              </Intro>

              <SocialLinks />

              <Intro  visible={refVideoInView} in={{ fadeRight: 700 }} delayIn={1100} mounted={true} stay={true} className="padd-top-half overflow-visible stay-connected-wrap uppercase social-link">
                <p>Site By <a target="_blank" rel="noreferrer noopener" href="https://www.deepsleepstudio.com/" style={{display: 'inline-block', color: "#000"}}>Deepsleep Studio</a></p>
              </Intro>

            </div>

            <div className="span-9 span-12-mobile overflow-hidden padd-right-half as-center">
              <Intro visible={refVideoInView} in={{ bg: 750, fade: 750 }} delayIn={600} mounted={true} stay={true} className="video-box c5 t">
                <SanitySlider {...data?.sanityPageLanding?.splash_slider[0]} />
              </Intro>
            </div>
          </div>

        </section>
  
      </main>
            

      {quickHTML ?
        <div
          dangerouslySetInnerHTML={{ __html: quickHTML }}
        />
      : null }
      
    </>
  )
}

export default Page

export const query = graphql`
  query {
    sanityPageLanding {
      splash_slider {
        ...sanitySlider
      }
      ticker
      quickHTML {
        code
      }
    }
  }
`